import axios, { CancelTokenSource } from "axios";
import { deleteCookie } from "cookies-next";

import { verifyLogin } from "../middleware";
import {
	Operator,
	Tariff,
	ClicksPerDay,
	AdminImportResult,
	UpdateTariff,
	ImportStatus,
	AppMetricsResponse,
} from "../types/api";
import app from "./app";
import { authKey } from "../pages/login";

export const apiEndpoint =
	process.env.NEXT_PUBLIC_API_ENDPOINT ?? "https://admin.ladefuchs.app/admin";

axios.defaults.baseURL = apiEndpoint;
axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/json";

let cancelToken: CancelTokenSource | null = null;

export async function login(username: string, password: string) {
	const payload = {
		username,
		password,
	};
	return await axios.post("login", payload);
}

export async function checkLogin(cookie: string | null): Promise<any | null> {
	const redirect = {
		redirect: {
			destination: app.routes.login.path,
			permanent: false,
		},
	};

	if (!cookie) {
		return redirect;
	}

	const status = await verifyLogin(cookie);

	if (status !== 200) {
		return redirect;
	}

	return null;
}

export function clearStorage() {
	localStorage.setItem("username", "");
	deleteCookie(authKey, {
		path: "/",
		domain: window.location.hostname,
	});
}

export async function logout() {
	try {
		await axios.post("logout");
	} catch (err: any) {
		console.error(err.message);
	}
	clearStorage();
}

export async function getTariffs(cookie: string): Promise<Tariff[]> {
	let list = [];
	try {
		const resp = await axios.get("auth/tariffs", {
			headers: {
				cookie,
			},
		});
		if (resp.status === 200) {
			list = resp.data;
		}
	} catch (err) {
		console.error(err);
	}

	return list;
}

export async function getLastImport(cookie = ""): Promise<AdminImportResult> {
	try {
		if (cookie) {
			const resp = await axios.get("auth/import/last", {
				headers: {
					cookie,
				},
			});
			return resp.data;
		}
		const resp = await axios.get("auth/import/last");
		return resp.data;
	} catch (error: any) {
		return {
			status: ImportStatus.Failure,
			importResult: null,
			error: error.message,
		};
	}
}

export async function triggerImport(): Promise<void> {
	await axios.post("auth/import/start");
}

function removeNonAlphaCharacters(inputString: string): string {
	// Use regular expression to remove all non-alphabetic characters
	return inputString.replace(/[^a-zA-Z]/g, "");
}

export async function searchOperator(query: string): Promise<Array<Operator>> {
	if (!query.trim()) {
		return [];
	}
	try {
		if (cancelToken) {
			cancelToken.cancel("Operation canceled due to new request.");
		}

		cancelToken = axios.CancelToken.source();

		const resp = await axios.post("auth/operators/search", {
			query: removeNonAlphaCharacters(query),
			cancelToken,
		});
		return resp.data;
	} catch (err: any) {
		console.error(err.message);
	}

	return [];
}

export async function addOrUpdateOperator(
	operator: Operator
): Promise<Operator> {
	const resp = await axios.patch("auth/operator", operator);
	return resp.data;
}

export async function patchTariff(tariff: UpdateTariff): Promise<void> {
	await axios.patch(`auth/tariff`, tariff);
}

export async function getBannerChartValues({
	cookie = null,
	days,
	linkId,
}: {
	cookie?: string | null;
	days: number;
	linkId: number;
}): Promise<ClicksPerDay[]> {
	let list = [];
	try {
		const headers = cookie ? { cookie } : {};
		const resp = await axios.get(`auth/stats/banner/${days}/${linkId}`, {
			headers,
		});
		if (resp.status === 200) {
			list = resp.data;
		}
	} catch (err) {
		console.error(err);
	}

	return list;
}

export async function getOperators({
	cookie,
	standard,
}: {
	cookie: string;
	standard: boolean;
}): Promise<Operator[]> {
	let list = [];
	try {
		const json = await axios.get(`auth/operators?standard=${standard}`, {
			headers: {
				cookie,
			},
		});
		list = json.data;
	} catch (err: any) {
		console.error(err.message);
	}
	return list;
}

export async function getAppMetrics({
	cookie,
	days,
}: {
	cookie: string;
	days: number;
}): Promise<AppMetricsResponse> {
	try {
		const response = await axios.get(`auth/app/metrics?days=${days}`, {
			headers: {
				cookie,
			},
		});
		return response.data;
	} catch (err: any) {
		console.error(err.message);
	}
	return {
		usageByPlatform: {
			android: 0,
			ios: 0,
			total: 0,
		},
		usageGroupByDay: [],
		totalBannerImpression: 0,
	};
}

// export async function getClickMetrics({
// 	cookie,
// 	linkId,
// }: {
// 	cookie: string;
// 	linkId: number;
// }): Promise<ThgClickSummary | null> {
// 	try {
// 		const resp = await axios.get(`auth/stats/banner/summary/${linkId}`, {
// 			headers: {
// 				cookie,
// 			},
// 		});
// 		if (resp.status === 200) {
// 			return resp.data;
// 		}
// 	} catch (err) {
// 		console.error(err);
// 	}

// 	return null;
// }
