"use client";

import "../styles/globals.css";

import type { AppProps } from "next/app";
import { AuthContextProvider } from "../contexts/authContext";

import AppLayout from "../components/layout/layout";
import { useRouter } from "next/router";
import { NextUIProvider } from "@nextui-org/system";
import { Toaster } from "react-hot-toast";
import Head from "next/head";

export default function App({ Component, pageProps }: AppProps) {
	const router = useRouter();

	return (
		<>
			<Head>
				<title>Ladefuchs Admin</title>
				<meta
					name="description"
					content="The Ladefuchs Admin for managing the backend"
				/>
				<link rel="manifest" href="/manifest.json" />
				<meta name="theme-color" content="#FFF" />
				<link rel="icon" href="/favicon.ico" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
				/>
			</Head>
			<NextUIProvider navigate={router.push}>
				<AuthContextProvider>
					<AppLayout>
						<Component {...pageProps} />
						<Toaster />
					</AppLayout>
				</AuthContextProvider>
			</NextUIProvider>
		</>
	);
}
